import React, { useState } from "react"
import { Button, Box, MenuItem, Tooltip, CircularProgress } from "@mui/material"
import { colors, Select } from "@parallel-domain/pd-theme"
import { useRouter } from "next/router"
import { SxProps, Theme } from "@mui/material/styles"
import { Download as DownloadIcon, NavArrowDown } from "iconoir-react"
import { toast } from "react-toastify"
import axios, { AxiosResponse } from "axios"

// import app components
import useReleases from "../store/useReleases"
import useCustomSession from "features/authentication/hooks/useSession"

export type Props = {
  sx?: SxProps<Theme>
}

const ReleaseToggle = (props: Props) => {
  const { sx = {} } = props

  const { data: session } = useCustomSession()

  const [isLoading, setIsLoading] = useState(false)

  const router = useRouter()

  const activeRelease = useReleases((state) => state.activeRelease)
  const setActiveRelease = useReleases((state) => state.setActiveRelease)

  const handleSelectRelease = (name: string) => {
    setActiveRelease(name)

    if (router.asPath.startsWith("/explore")) {
      // Replace version in pathname
      const newPathname = router.asPath.replace(activeRelease, name)
      router.push(newPathname)
    }
  }

  const handleDownload = async () => {
    try {
      setIsLoading(true)

      const response: AxiosResponse<{ url: string }> = await axios.post("/api/content/download-pd-sdk-release", {
        release: activeRelease,
      })

      // Create a hidden anchor element
      const link = document.createElement("a")
      link.href = response.data.url
      link.setAttribute("download", "") // Set 'download' attribute to trigger download

      // Append the link to the body
      document.body.appendChild(link)

      // Programmatically click the link to start the download
      link.click()

      // Remove the link after triggering the download
      document.body.removeChild(link)
    } catch (e) {
      const error = e as { response: { data: { message: string } } }
      toast.error(error.response.data.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        border: 1,
        borderColor: colors.border[800],
        borderRadius: 2,
        height: 42,
        ...sx,
      }}
    >
      <Select
        value={activeRelease}
        sx={{
          flex: 1,
          ".MuiInputBase-root": { background: "transparent", border: "none", minHeight: "unset" },
          ".MuiSelect-select": { fontSize: 12, py: "10px !important" },
        }}
        IconComponent={NavArrowDown}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectRelease(e.target.value)}
      >
        {session.user.activeOrganization.releases.map((name) => {
          return (
            <MenuItem key={name} value={name} sx={{ py: 1 }}>
              {name}
            </MenuItem>
          )
        })}
      </Select>

      <Tooltip title="Download PD-SDK">
        <Button
          sx={{
            borderLeft: 1,
            borderColor: colors.border[800],
            borderRadius: 0,
            p: "0 !important",
            minHeight: "unset",
            height: "42px !important",
            width: "42px !important",
          }}
          disabled={isLoading}
          onClick={handleDownload}
        >
          {isLoading ? (
            <CircularProgress size={18} />
          ) : (
            <DownloadIcon width={18} height={18} style={{ color: colors.icons[400] }} />
          )}
        </Button>
      </Tooltip>
    </Box>
  )
}

export default ReleaseToggle
