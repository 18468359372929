import { useState } from "react"
import { Alert, AlertTitle, Stack, Typography, Divider, Link, Popover, useMediaQuery, useTheme } from "@mui/material"
import NextLink from "next/link"
import { format } from "date-fns"
import { Paper, colors, Button } from "@parallel-domain/pd-theme"
import { WarningCircle } from "iconoir-react"

// import app components
import { impactColors } from "../constants"
import type { Status, Incident, ScheduledMaintenance } from "../types"

type SummaryProps = {
  status: Status
  incidents: Incident[]
  scheduled_maintenances: ScheduledMaintenance[]
  showLink?: boolean
}

const StatusDropdown = (props: SummaryProps) => {
  const { status, incidents, scheduled_maintenances, showLink } = props

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down(1440))

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const hasNotifications = incidents?.length > 0 || scheduled_maintenances?.length > 0

  if (!hasNotifications) return null

  return (
    <>
      <Button
        onClick={handleClick}
        variant="contained"
        size="small"
        color={impactColors?.[status.indicator] || "primary"}
        startIcon={!isMobile && <WarningCircle width={18} height={18} />}
      >
        {isMobile ? <WarningCircle width={18} height={18} /> : status.description}
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack spacing={4} sx={{ p: 3, maxWidth: 800, width: "100%", background: colors.secondary[800] }}>
          <Stack spacing={1}>
            <Typography variant="subtitle2" color="text.secondary">
              System Status
            </Typography>

            {incidents && incidents.length > 0 ? (
              <Alert severity={impactColors?.[status.indicator]} sx={{ ".MuiAlert-message": { width: "100%" } }}>
                <Stack spacing={1}>
                  <AlertTitle>{status.description}</AlertTitle>

                  <Divider />

                  <Stack spacing={2}>
                    {incidents.map((o) => (
                      <Stack key={o.id} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                        <Stack spacing={0}>
                          <Link
                            href={`/data-lab/status/incidents/${o.id}`}
                            component={NextLink}
                            underline="none"
                            onClick={handleClose}
                          >
                            <Typography>
                              <strong style={{ textTransform: "capitalize" }}>{o.status}</strong> - {o.name}
                            </Typography>
                          </Link>
                          <Typography variant="subtitle1" sx={{ color: colors.text[700] }}>
                            Last updated at {format(new Date(o.updated_at), "MMM dd, yyyy HH:mm")}
                          </Typography>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </Alert>
            ) : (
              <Alert
                severity={impactColors?.[status.indicator]}
                sx={{ ".MuiSvgIcon-root": { color: "inherit" }, ".MuiAlert-action": { pt: 0, alignItems: "center" } }}
                action={
                  showLink && (
                    <NextLink href="/data-lab/status" style={{ color: "inherit" }}>
                      <Button color="inherit" size="small">
                        Learn more
                      </Button>
                    </NextLink>
                  )
                }
              >
                {status.description}
              </Alert>
            )}
          </Stack>

          {scheduled_maintenances && scheduled_maintenances.length > 0 && (
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="text.secondary">
                Scheduled Maintenance
              </Typography>

              <Stack spacing={2}>
                {scheduled_maintenances.map((o) => (
                  <Paper key={o.id}>
                    <Stack key={o.id} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                      <Stack spacing={0}>
                        <Link
                          href={`/data-lab/status/incidents/${o.id}`}
                          component={NextLink}
                          underline="none"
                          onClick={handleClose}
                        >
                          <Typography>{o.name}</Typography>
                        </Link>
                        <Typography variant="subtitle1" sx={{ color: colors.text[700] }}>
                          Scheduled for {format(new Date(o.scheduled_for), "MMM dd, yyyy HH:mm")}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Popover>
    </>
  )
}

export default StatusDropdown
