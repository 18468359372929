import axios, { AxiosResponse } from "axios"

// import app components
import type { Summary } from "../types"

const getSummary = async () => {
  const response: AxiosResponse<Summary> = await axios.get(`${process.env.NEXT_PUBLIC_STATUS_API}/api/v2/summary.json`)
  return response.data
}

export default getSummary
