import { AlertColor, Tooltip, IconButton } from "@mui/material"
import {
  CheckCircle as CheckIcon,
  Search as IdentifiedIcon,
  PageSearch as InvestigateIcon,
  StatsReport as MonitoringIcon,
  Flash as OutageIcon,
  StatDown as DegradedPerformanceIcon,
  Tools as MaintenanceIcon,
  Clock as ScheduledIcon,
  Tools as InProgressIcon,
} from "iconoir-react"

export const indicatorColors = {
  operational: "success",
  major_outage: "error",
  partial_outage: "warning",
  degraded_performance: "warning",
  under_maintenance: "info",
} as { [key: string]: AlertColor }

export const impactColors = {
  none: "success",
  major: "error",
  minor: "warning",
  critical: "error",
} as { [key: string]: AlertColor }

export const incidentTypes = {
  // Incidents
  resolved: (
    <Tooltip title="Resolved" disableInteractive>
      <CheckIcon width={20} height={20} />
    </Tooltip>
  ),
  monitoring: (
    <Tooltip title="Monitoring">
      <MonitoringIcon width={20} height={20} />
    </Tooltip>
  ),
  identified: (
    <Tooltip title="Identified" disableInteractive>
      <IdentifiedIcon width={20} height={20} />
    </Tooltip>
  ),
  investigating: (
    <Tooltip title="Investigating" disableInteractive>
      <InvestigateIcon width={20} height={20} />
    </Tooltip>
  ),

  // Scheduled maintenance
  completed: (
    <Tooltip title="Completed" disableInteractive>
      <CheckIcon width={20} height={20} />
    </Tooltip>
  ),
  in_progress: (
    <Tooltip title="Completed" disableInteractive>
      <InProgressIcon width={20} height={20} />
    </Tooltip>
  ),
  scheduled: (
    <Tooltip title="Scheduled" disableInteractive>
      <ScheduledIcon width={20} height={20} />
    </Tooltip>
  ),
  verifying: (
    <Tooltip title="Scheduled" disableInteractive>
      <InvestigateIcon width={20} height={20} />
    </Tooltip>
  ),
}

export const statusTypes = {
  operational: (
    <Tooltip title="Operational" disableInteractive>
      <IconButton color={indicatorColors.operational} sx={{ cursor: "help" }}>
        <CheckIcon width={24} height={24} />
      </IconButton>
    </Tooltip>
  ),
  degraded_performance: (
    <Tooltip title="Degraded Performance" disableInteractive>
      <IconButton color={indicatorColors.degraded_performance} sx={{ cursor: "help" }}>
        <DegradedPerformanceIcon width={24} height={24} />
      </IconButton>
    </Tooltip>
  ),
  partial_outage: (
    <Tooltip title="Partial Outage" disableInteractive>
      <IconButton color={indicatorColors.partial_outage} sx={{ cursor: "help" }}>
        <OutageIcon width={24} height={24} />
      </IconButton>
    </Tooltip>
  ),
  major_outage: (
    <Tooltip title="Major Outage" disableInteractive>
      <IconButton color={indicatorColors.major_outage} sx={{ cursor: "help" }}>
        <OutageIcon width={24} height={24} />
      </IconButton>
    </Tooltip>
  ),
  under_maintenance: (
    <Tooltip title="Under Maintenance" disableInteractive>
      <IconButton color={indicatorColors.under_maintenance} sx={{ cursor: "help" }}>
        <MaintenanceIcon width={22} height={22} />
      </IconButton>
    </Tooltip>
  ),
}
